export const HOME = "/"
export const ACCOUNT = "/account"
export const ANONYMOUS_LINK = "/anonymous-page"
export const CHANGE_PASSWORD = "/change-password"
export const PASSWORD_FORGET = "/forget-password"
export const MENU = "/menu-page"
export const RESULT = "/result"
export const SIGN_IN = "/signin"
export const SIGN_OUT = "/signout"
export const SIGN_UP = "/signup"
export const QUIZ = "/quiz"
export const QUIZ_QUESTIONS = "/quiz-questions"
